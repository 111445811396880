<template>
    <div class="auth-way-container">
        <div v-if="!$ncInWxMiniApp">
            <divider class="mlr-6" style="color: #999999">其他登录方式</divider>
            <div class="flex-row auth-way mtb-2 fs-s">
                <a v-if="isModeSMS" class="flex-column-center" href="javascript:void(0);" @click="actionChange('password')"><img class="mb-1" :src="$ncimage('/images/mobile/login/account.png')" width="30px" />密码登录</a>
                <a v-if="!isModeSMS" class="flex-column-center" href="javascript:void(0);" @click="actionChange('sms')"><img class="mb-1" :src="$ncimage('/images/mobile/login/phone.png')" width="30px" />验证码登录</a>
                <template v-if="!isZhiYe">
                    <a class="flex-column-center" :href="wb"><img class="mb-1" :src="$ncimage('/images/mobile/login/weibo.png')" width="30px" />微博</a>
                    <a class="flex-column-center" :href="qq"><img class="mb-1" :src="$ncimage('/images/mobile/login/qq.png')" width="30px" />QQ</a>
                </template>
                <!-- <a v-if="weixin" class="flex-column-center" :href="weixin"><img class="mb-1" :src="$ncimage('/images/mobile/login/wechat.png')" width="30px" />微信</a> -->
            </div>
        </div>
    </div>
</template>
<script>
import {Divider} from '@ncfe/nc.vux';
import App from '@/js/lib/util/app';

export default {
    components: {Divider},
    props: ['isModeSMS'],
    data: fData,
    methods: {
        actionChange: fActionChange
    }
};

function fData() {
    let oInfo = (window.pageInfo || {}).loginUrl || {};
    return {
        wb: oInfo.wb,
        qq: oInfo.qq,
        weixin: oInfo.weixin,
        isZhiYe: App.isZhiYe
    };
}

function fActionChange(sType) {
    let that = this;
    that.$emit('change', sType);
}
</script>
<style lang="scss">
.auth-way {
    justify-content: space-between;
    box-sizing: border-box;
    a.flex-column-center {
        padding: 0 10px;
        color: $colorGray;
    }

    &:before,
    &:after {
        content: '';
        display: block;
    }
}
</style>
