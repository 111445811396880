<template>
    <div>
        <div class="mlr-4">
            <div class="flex-row-start input-wrap">
                <div class="flex-row-start flex-none" style="min-width: 100px" @click.stop="actionCountryCode">
                    <div class="flex-auto pl-2">
                        {{ countryName }}<span class="ml-1">{{ countryCode }}</span>
                    </div>
                    <i class="flex-none color-gray txt-right icon-arrow-down"></i>
                </div>
                <x-input ref="phone" v-model="phone" class="flex-auto" :placeholder="`${countryName}手机号`" type="tel" autocomplete="username"></x-input>
            </div>
            <div class="flex-row-start input-wrap mt-3">
                <x-input ref="code" v-model="code" class="flex-auto" placeholder="验证码" :show-clear="false" type="tel" autocomplete="one-time-code"></x-input>
                <div class="flex-none pr-2 color-green" @click="actionSendCode">{{ sendCodeText }}</div>
            </div>
            <div class="plr-2 mt-4">
                <div class="login-btn color-white txt-center fs-l" @click="submit">注册/登录</div>
            </div>
            <div v-if="weixin" class="plr-2 mt-2">
                <a :href="weixin"><div class="wechat-btn color-white txt-center fs-l">微信一键登陆</div></a>
            </div>
        </div>
        <PopupCaptcha ref="captcha" v-model="captchaShow" :type="captchaType" @verify="actionVerify" />
    </div>
</template>
<script>
import {XInput} from '@ncfe/nc.vux';
import PopupCaptcha from '@/js/cpn/popup/PopupCaptcha';
import UI from '@/js/lib/util/ui';
import ActivityAction from '@/js/lib/action/activity';
const {gio, commonPageEnter} = require('@ncfe/gio.lib');

let oInfo = (window.pageInfo || {}).loginUrl || {};
let LoginAction = require('@/js/lib/action/login');
let Conf = require('@/js/lib/conf');
let Uri = require('@ncfe/nc.uri');
let Base = require('@ncfe/nc.base');
let Str = require('@ncfe/nc.str');
let Time = require('@ncfe/nc.time');
let $ = require('@ncfe/nc.jquery');
let Util = require('@/js/lib/util/util');

let CODE_CHINA = '+86';

export default {
    components: {XInput, PopupCaptcha},
    props: ['mode'],
    data: fData,
    mounted: fMounted,
    methods: {
        check: fCheck,
        val: fVal,

        submit: fSubmit,
        springActivity: fSpringActivity,
        actionCountryCode: fActionCountryCode,
        actionSendCode: fActionSendCode,
        actionVerify: fActionVerify,

        checkPhone: fCheckPhone,
        showToast: fShowToast,

        encrypt: fEncrypt,
        getRequestBody: fGetRequestBody
    },
    computed: {
        fullPhone: fFullPhone,
        isModeResetPwd: fIsResetPwd,
        isModeSMSLogin: fIsModeSMSLogin
    }
};

function fData() {
    let that = this;
    return {
        register: false,
        captchaShow: false,
        captchaType: Conf.captcha.sendVerifyCode,

        countryCode: CODE_CHINA,
        countryName: '中国',
        phone: '',
        code: '',

        sendCodeText: '获取验证码',
        weixin: oInfo.weixin
    };
}

function fMounted() {
    let that = this;
    $(document.body).append(that.$refs.captcha.$el);
}

function fCheck() {
    let that = this;
    let oData = that.val();
    let bChina = that.countryCode === CODE_CHINA;
    let sAccount = oData.phone;
    let msg = '';
    if (!sAccount) msg = '请输入手机号码';
    else if (bChina ? !Str.isPhone(sAccount) : !/^\d+$/.test(sAccount)) msg = '请输入正确的手机号码';
    else if (!oData.code) msg = '请输入验证码';
    msg && that.showToast(msg);
    return !msg;
}

function fVal() {
    let that = this;
    // 再次阅读input 因为 Chrome Autocomplete不发事件
    that.phone = $(that.$refs.phone.$el).find('input').val();
    that.code = $(that.$refs.code.$el).find('input').val();

    return {
        countryCode: $.trim(that.countryCode),
        phone: $.trim(that.phone),
        code: $.trim(that.code)
    };
}

function fActionCountryCode() {
    let that = this;
    UI.popupCountryCode({
        call: oItem => {
            that.countryCode = oItem.code;
            that.countryName = oItem.name;
        }
    });
}

function fSubmit() {
    let that = this;
    if (!that.check()) return;
    let bRight = true;
    let sCb = Uri.getParam('callBack');
    sCb = (sCb ? decodeURIComponent(window.unescape(sCb)) : '') || '/home';
    if (that.isModeSMSLogin && !that.register) {
        let oData = that.getRequestBody();
        bRight &&
            LoginAction.login({
                body: {account: oData.phone, code: oData.code},
                call: oResult => {
                    // 春招活动 ip归因有问题，登录之后需要调一下后端接口
                    if (Uri.getParam('isSpringActivity')) return this.springActivity();
                    sCb = Uri.addParam({time: new Date().getTime()}, sCb);
                    window.location.href = sCb;
                    gio('track', 'loginSuccess', {
                        pageName_var: commonPageEnter(decodeURIComponent(sCb)),
                        platform_var: 'H5'
                    });
                },
                error: oResult => that.showToast(oResult.msg)
            });
    } else if (that.register) {
        bRight &&
            LoginAction.registerPhone({
                body: {...that.getRequestBody(), from: Uri.getParam('from')},
                call: oResult => {
                    that.showToast('注册成功');
                    // 谷歌注册转化
                    window.gtag_report_conversion && window.gtag_report_conversion();
                    window.setTimeout(() => {
                        // 春招活动 ip归因有问题，登录之后需要调一下后端接口
                        if (Uri.getParam('isSpringActivity')) return this.springActivity();
                        window.location.href = `/completeness?from=${encodeURIComponent(sCb)}&callBack=${encodeURIComponent(sCb)}`;
                    }, 250);

                    gio('track', 'registerSuccess', {
                        pageName_var: commonPageEnter(decodeURIComponent(sCb)),
                        platform_var: 'H5'
                    });
                },
                error: oResult => that.showToast(oResult.msg)
            });
    }
}

function fSpringActivity() {
    try {
        const {callBack, isNeedLogin, isNeedLocation, fromPut} = Uri.getParams();
        const href = `${callBack}?${isNeedLogin ? 'isNeedLogin=1&' : ''}${isNeedLocation ? 'isNeedLocation=1' : ''}`;
        window.location.href = Util.jumpUrl(href, '/home');
        ActivityAction.receiveJoin({query: {type: 10, fromPut}});
    } catch (error) {
        console.log('error', error);
    }
}

function fActionSendCode() {
    let that = this;
    let bChina = that.countryCode === CODE_CHINA;
    let sAccount = that.phone;
    if (bChina ? !Str.isPhone(sAccount) : !/^\d+$/.test(sAccount)) {
        that.showToast('请输入正确的手机号码');
        return false;
    }
    !that.isSending &&
        that.checkPhone(that.fullPhone, function (bRight) {
            bRight && (that.captchaShow = true);
        });
}

function fActionVerify(oData) {
    let that = this;
    that.isSending = true;
    LoginAction.sendMobileCodeV2({
        body: Base.extend({phone: that.fullPhone}, oData),
        call: function () {
            that.showToast('验证码发送成功');
            Time.countDown({
                second: 120,
                call: nSecond => (that.sendCodeText = nSecond + '秒重新获取'),
                end: () => {
                    that.sendCodeText = '重新获取验证码';
                    that.isSending = false;
                }
            });
        },
        error: oResult => {
            that.isSending = false;
            that.showToast(oResult.msg);
        }
    });
}

function fCheckPhone(sPhone, fCb) {
    let that = this;
    let oPhoneMap = (that.phoneMap = that.phoneMap || {});
    let oPhoneTipsMap = (that.phoneTipsMap = that.phoneTipsMap || {});
    if (!sPhone) {
        return fCb && fCb(false);
    }
    if (sPhone in oPhoneMap) {
        return _fDeal(oPhoneMap[sPhone], oPhoneTipsMap[sPhone]);
    }
    LoginAction.checkPhone({
        query: {phone: that.getRequestBody().phone},
        call: () => _fDeal(true),
        error: oResult => _fDeal(false, oResult)
    });
    function _fDeal(bRight, oResult) {
        oPhoneMap[sPhone] = bRight;
        oPhoneTipsMap[sPhone] = oResult;
        if (that.isModeSMSLogin) {
            if (bRight) {
                that.captchaShow = true;
                that.register = true;
            }
            !bRight && fCb && fCb(true);
        } else {
            if (!bRight) {
                oResult = oResult || {};
                let sMessage = '该手机号已注册<br />请直接登录' || oResult.msg;
                that.showToast(sMessage);
            }
            fCb && fCb(bRight);
        }
    }
}

function fShowToast(sContent) {
    let that = this;
    that.$vux.toast.show({
        type: 'text',
        text: sContent,
        width: '10em',
        position: 'middle'
    });
}

function fEncrypt(sVal) {
    let oCrypt = new window.NCJSEncrypt();
    oCrypt.setPublicKey($('#jsNCPublicKey').html() || '');
    return oCrypt.encrypt($.trim(sVal));
}

function fGetRequestBody() {
    let that = this;
    let oData = that.val();
    oData.phone = that.fullPhone;
    oData.client = '2';
    delete oData.countryCode;
    return oData;
}

function fFullPhone() {
    let that = this;
    return `${that.countryCode || ''}${that.phone || ''}`;
}

function fIsResetPwd() {
    let that = this;
    return that.mode === 'resetPwd';
}

function fIsModeSMSLogin() {
    let that = this;
    return that.mode === 'sms';
}
</script>
<style lang="scss">
.input-wrap {
    background: #f7f7fb;
    border-radius: 5px;
    padding: 2px 0;
}

.login-btn {
    background: #1ebf9b;
    line-height: 40px;
    border-radius: 20px;
}

.wechat-btn {
    background: #0cbf08;
    line-height: 40px;
    border-radius: 20px;
}

.weui-cell:before {
    border-top: none;
}
input::-webkit-input-placeholder {
    color: #999999;
}
</style>
