<template>
    <div class="bg-white" style="min-height: 100vh">
        <Bg :height="52" :src="bgSrc"></Bg>
        <div class="ptb-4">
            <FormWithCode v-if="isModeSMS" mode="sms"></FormWithCode>
            <template v-else>
                <div class="mlr-4">
                    <div v-if="isModeOverseas" class="flex-row-start input-wrap">
                        <div class="flex-row-start flex-none" style="min-width: 100px" @click.stop="selectCountryCode">
                            <div class="flex-auto pl-2">
                                {{ countryName }}<span class="ml-1">{{ countryCode }}</span>
                            </div>
                            <i class="flex-none color-gray txt-right icon-arrow-down"></i>
                        </div>
                        <x-input ref="account" v-model="account" class="flex-auto" :placeholder="`${countryName}手机号`" type="tel" autocomplete="username"></x-input>
                    </div>
                    <div v-else class="flex-row-start input-wrap">
                        <x-input ref="account" v-model="account" class="flex-auto" placeholder="手机号/邮箱" type="text" autocomplete="username"></x-input>
                    </div>
                    <div class="flex-row-start input-wrap mt-3">
                        <x-input ref="password" v-model="password" type="password" placeholder="密码" autocomplete="current-password" style="width: 100%" />
                    </div>
                    <div class="plr-2 mt-4">
                        <div class="login-btn color-white txt-center fs-l" @click="login">登录</div>
                    </div>
                </div>
            </template>
            <div class="p-2 clearfix">
                <span v-if="isModePassword" class="mb-2 color-green left" @click="actionGoOverseas">海外手机登录</span>
                <span v-if="isModeOverseas" class="mb-2 color-green left" @click="actionGoPassword">账号密码登录</span>
                <a v-if="!isModeSMS" class="right color-gray ml-2" :href="forgotPwdUrl">忘记密码</a>
            </div>
            <AuthWay :isModeSMS="isModeSMS" @change="actionChange"></AuthWay>
        </div>
        <div class="flex-column-center fs-xs p-1 color-gray">
            <div class="mb-1 txt-center">点击“注册/登录”按钮，即表示你同意<a href="https://static.nowcoder.com/protocol/register.html" target="_blank">《注册协议》</a>和<a href="https://static.nowcoder.com/protocol/privacy-policy.html" target="_blank">《隐私政策》</a></div>
            <div>
                牛客网&copy;<span>{{ new Date().getFullYear() }}</span> All Rights Reserved
            </div>
        </div>
        <PopupCaptcha ref="captcha" v-model="captchaShow" :custom_id="'login_Captcha'" :type="captchaType" @verify="actionVerify" />
    </div>
</template>

<script>
import {XInput} from '@ncfe/nc.vux';
import AuthWay from './AuthWay';
import FormWithCode from './FormWithCode';
import flex from '@/js/lib/mixin/flex';
import UI from '@/js/lib/util/ui';
import Bg from '@nc/vue.bg';
import ActivityAction from '@/js/lib/action/activity';
import PopupCaptcha from '@/js/cpn/popup/PopupCaptcha';
const {gio, commonPageEnter} = require('@ncfe/gio.lib');

let Util = require('@/js/lib/util/util');
let Uri = require('@ncfe/nc.uri');
let Str = require('@ncfe/nc.str');
let $ = require('@ncfe/nc.jquery');
let LoginAction = require('@/js/lib/action/login');
let Conf = require('@/js/lib/conf');

let CODE_CHINA = '+86';

export default {
    components: {XInput, AuthWay, FormWithCode, Bg, PopupCaptcha},
    mixins: [flex],
    data: fData,
    methods: {
        check: fCheck,
        val: fVal,

        actionGoOverseas: fActionGoOverseas,
        actionGoPassword: fActionGoPassword,
        selectCountryCode: fSelectCountryCode,
        login: fLogin,
        springActivity: fSpringActivity,
        showToast: fShowToast,
        encrypt: fEncrypt,
        actionChange: fActionChange,
        actionVerify: fActionVerify
    },
    computed: {
        forgotPwdUrl: fForgotPwdUrl,

        isModeSMS: fIsModeSMS,
        isModePassword: fIsModePassword,
        isModeOverseas: fIsModeOverseas
    }
};

function fData() {
    let that = this;
    let sType = Uri.getParam('type');
    // 自定义背景图
    let loginBg = Uri.getParam('loginBg');
    loginBg && !Util.isNcUrl(loginBg) && (loginBg = '');
    // 自定义标题
    let loginTitle = Uri.getParam('loginTitle');
    loginTitle && (document.title = loginTitle);

    return {
        bgSrc: loginBg || $ncimage('/images/mobile/login/banner-bg.png'),
        mode: sType === 'overseas' ? 'overseas' : 'sms',
        account: '',
        countryCode: CODE_CHINA,
        countryName: '中国',
        password: '',
        captchaShow: false,
        captchaType: Conf.captcha.login
    };
}

function fCheck() {
    let that = this;
    let oData = that.val();
    let bChina = that.countryCode === CODE_CHINA;
    let sAccount = oData.account;
    if (!sAccount) {
        that.showToast('请输入账号');
        return false;
    }
    if (!that.isModeOverseas && !Str.isEmail(sAccount) && !Str.isPhone(sAccount)) {
        that.showToast('请输入正确的账号信息');
        return false;
    }
    if (that.isModeOverseas && (bChina ? !Str.isPhone(sAccount) : !/^\d+$/.test(sAccount))) {
        that.showToast('请输入正确的手机号码');
        return false;
    }
    if (!oData.password) {
        that.showToast('请输入密码');
        return false;
    }
    return true;
}

function fVal(oData) {
    let that = this;
    if (arguments.length === 0) {
        // 再次阅读input 因为 Chrome Autocomplete不发事件
        that.account = $(that.$refs.account.$el).find('input').val();
        that.password = $(that.$refs.password.$el).find('input').val();

        return {
            countryCode: $.trim(that.countryCode),
            account: $.trim(that.account),
            password: $.trim(that.password)
        };
    }
    that.account = oData.account;
    that.password = oData.password;
}

function fActionGoOverseas() {
    let that = this;
    that.mode = 'overseas';
}

function fActionGoPassword() {
    let that = this;
    that.mode = 'password';
}

function fSelectCountryCode() {
    let that = this;
    UI.popupCountryCode({
        call: oItem => {
            that.countryCode = oItem.code;
            that.countryName = oItem.name;
        }
    });
}

function fLogin({netease_validate}) {
    let that = this;
    if (!that.check()) return;
    let oData = that.val();
    that.isModeOverseas && (oData.account = `${that.countryCode}${that.account}`);
    oData.cipherPwd = that.encrypt(oData.password);
    delete oData.countryCode;
    delete oData.password;
    netease_validate && (oData.netease_validate = netease_validate);

    LoginAction.login({
        body: oData,
        call: oResult => {
            let sHref = Uri.getParam('callBack');
            sHref = sHref ? decodeURIComponent(window.unescape(sHref)) : null;
            // 春招活动 ip归因有问题，登录之后需要调一下后端接口
            if (Uri.getParam('isSpringActivity')) return this.springActivity();
            window.location.href = Util.jumpUrl(sHref, '/home');

            gio('track', 'loginSuccess', {
                pageName_var: commonPageEnter(decodeURIComponent(sHref)),
                platform_var: 'H5'
            });
        },
        error: oResult => {
            if (oResult.code === 1125) {
                that.captchaShow = true;
            } else {
                that.showToast(oResult.msg);
            }
        }
    });
}
function fSpringActivity() {
    try {
        const {callBack, isNeedLogin, isNeedLocation, fromPut} = Uri.getParams();
        const href = `${callBack}?${isNeedLogin ? 'isNeedLogin=1&' : ''}${isNeedLocation ? 'isNeedLocation=1' : ''}`;
        window.location.href = Util.jumpUrl(href, '/home');
        ActivityAction.receiveJoin({query: {type: 10, fromPut}});
    } catch (error) {
        console.log('error', error);
    }
}

function fShowToast(sContent) {
    let that = this;
    that.$vux.toast.show({
        type: 'text',
        text: sContent,
        width: '10em',
        position: 'middle'
    });
}

function fEncrypt(sVal) {
    let oCrypt = new window.NCJSEncrypt();
    oCrypt.setPublicKey($('#jsNCPublicKey').html() || '');
    return oCrypt.encrypt($.trim(sVal));
}

function fForgotPwdUrl() {
    let that = this;
    return Uri.addParam(Uri.getParams(), '/forgot-pwd');
}

function fIsModeSMS() {
    let that = this;
    return that.mode === 'sms';
}

function fIsModeOverseas() {
    let that = this;
    return that.mode === 'overseas';
}

function fIsModePassword() {
    let that = this;
    return that.mode === 'password';
}

function fActionChange(sType) {
    let that = this;
    that.mode = sType;
}

function fActionVerify(oData) {
    let that = this;
    that.login(oData);
}
</script>

<style lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #f7f7fb inset;
    -webkit-text-fill-color: #333;
}

@media screen and (max-width: 350px) {
    .flex-container {
        overflow: auto !important;
        height: auto !important;
    }
}

.absolute {
    position: absolute;
    width: 100%;
}

.login-btn {
    background: #1ebf9b;
    line-height: 40px;
    border-radius: 20px;
}

.input-wrap {
    background: #f7f7fb;
    border-radius: 5px;
    padding: 2px 0;
}
</style>
